@keyframes zoomOutAnimation {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

.videoZoomOut {
  animation: zoomOutAnimation 1s ease-out forwards;
  animation-delay: 0.07s;
  transform: scale(1.2);
}
