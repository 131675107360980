.textGradient {
  background: linear-gradient(to bottom right, var(--lime-9), var(--lime-9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes pulseBackground {
  0%,
  100% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(140%);
  }
}

.pulse {
  animation: pulseBackground 3s linear infinite;
}
