@property --myColor1 {
  syntax: "<color>";
  initial-value: #fff;
  inherits: false;
}

@property --myColor2 {
  syntax: "<color>";
  initial-value: transparent;
  inherits: false;
}

.backgroundGradient {
  background: linear-gradient(var(--myColor1), var(--myColor2) 400px);
  transition:
    --myColor1 0.5s,
    --myColor2 0.5s;
  --myColor1: var(--accent-5);
  --myColor2: var(--mauve-1);
}

.backgroundGradient[data-character="true"] {
  --myColor1: var(--orange-4);
  --myColor2: var(--mauve-1);
}
